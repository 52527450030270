1<template>
  <LiefengContent class="vote">
    <template v-slot:title> 投票组件 </template>
    <template v-slot:toolsbarRight>
       <Form :label-colon="true" :inline="true" class="search">
              <FormItem>
                 <Input
                  :maxlength="20"
                    enter-button
                    v-model.trim="searchData.title"
                    placeholder="请输入信息名称"
                    style="margin-right:10px;width: 150px"
                  />
              </FormItem>
              <FormItem>
                 <Input
                  :maxlength="20"
                    enter-button
                    width="100px"
                    v-model.trim="searchData.code"
                    placeholder="请输入信息编码"
                    style="margin-right:6px;width:150px"
                  />
              </FormItem>
              <Button style="margin-right:10px" type="primary" icon="ios-search" @click="search">搜索</Button>
              <Button style="margin-right:10px" type="success" icon="ios-refresh" @click="rest">重置</Button>
              <Button
                style="margin-right:10px"
                type="primary"
                @click="addVote"
                icon="ios-add"
                >新增</Button
              >
                <!-- :style="{ display: buttonRoot == '1001' ? '' : 'none' }" -->
              <Button
                style="margin-right:10px"
                @click="exportExcel"
                type="error"
                icon="ios-cloud-download-outline"
                >导出</Button
              >
       </Form>
      
     
       
    </template>
    <template v-slot:contentArea>
      <div class="table-left">
        <Menu theme="light" :active-name="activeMenu" @on-select="selectMenu">
          <MenuGroup title="信息状态">
            <MenuItem name="2" v-if="buttonRoot == '1001'">
              待提交/已驳回({{ status2 }})
            </MenuItem>
            <MenuItem name="1" v-if="buttonRoot == '1002'">
              待审核({{ status1 }})
            </MenuItem>
            <MenuItem name="3" v-if="buttonRoot == '1003'">
              待发布({{ status3 }})
            </MenuItem>
            <MenuItem name="4">已发布 <Tooltip placement="right" content="已发布的内容居民在小程序、APP等终端可正常查看到。"><Icon type="ios-help-circle-outline" size="20"/></Tooltip></MenuItem>
             <MenuItem name="5">已过期 <Tooltip  placement="right" content="到了截止时间内容则为已过期，在小程序的全部和往期回顾中可查看到。"><Icon type="ios-help-circle-outline" size="20"/></Tooltip></MenuItem>
            <!-- <MenuItem name="6">已归档<Tooltip :max-width="500" placement="right" content="归档后的内容在小程序、APP等终端则不显示，对用户不可见。><Icon type="md-help" /></Tooltip></MenuItem> -->
            <MenuItem name="7">全部信息</MenuItem>
          </MenuGroup>
        </Menu>
      </div>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="currentPage"
        @hadlePageSize="pageChange"
        :loading="loading"
        :fixTable="true"
        :pagesizeOpts="[20, 30, 50, 100]"
        :pageSize="pageSize"
      ></LiefengTable>
      <LiefengModal
        :title="addChangeTitle"
        :value="addChangeModalStatus"
        :fullscreen="true"
        @input="addChangeModalFn"
      >
        <template v-slot:contentarea>
          <AddVote
            ref="addVote"
            @comSelectMenu="comSelectMenu"
            @addChangeModalFn="addChangeModalFn"
            @pageChange="pageChange"
            :menuCodeObj="menuCodeObj"
            v-if="addVoteStatus"
          ></AddVote>
          <ChangeVote
            ref="changeVote"
            @messageStatusCount="messageStatusCount"
            @comSelectMenu="comSelectMenu"
            :infoId="infoId"
            @addChangeModalFn="addChangeModalFn"
            @pageChange="pageChange"
            :menuCodeObj="menuCodeObj"
            v-if="changeVoteStatus"
          ></ChangeVote>
          <PublishVote
            ref="publishVote"
            @messageStatusCount="messageStatusCount"
            @comSelectMenu="comSelectMenu"
            :infoId="infoId"
            @modalStatusFn="modalStatusFn"
            @addChangeModalFn="addChangeModalFn"
            @pageChange="pageChange"
            :menuCodeObj="menuCodeObj"
            v-if="publishVoteStatus"
            :orgCode="orgCode"
          ></PublishVote>
        </template>
        <template v-slot:toolsbar>
          <Button type="primary" @click="backStep" :disabled="disableBtn == 0 ? true : false"
            >上一步</Button
          >
          <Button
          type="primary"
            @click="nextStep"
            style="margin-left: 10px"
            :disabled="disableBtn == 3 ? true : false"
            >下一步</Button
          >
          <Button
              type="success"
              @click="saveVote"
              style="margin-left: 10px"
              :style="{ display: buttonRoot == '1001' ? '' : 'none' }"
          >保存
          </Button
          >
<!--          <Button type="warning" style="margin-left: 10px"-->
<!--                  :style="{ display: buttonRoot == '1001' ? '' : 'none' }"-->
<!--                  @click="contentFn">预览文本</Button>-->
          <Button
          type="error"
            @click="rejectVote"
            style="margin-left: 10px"
            :style="{ display: buttonRoot == '1002' || buttonRoot == '1003' ? '' : 'none' }"
            >驳回</Button
          >
          <Button
            type="primary"
            @click="saveVote"
            style="margin-left: 10px"
            :style="{
              display:
                buttonRoot == '1002' || buttonRoot == '1003' ? '' : 'none',
            }"
            >{{
              publishRightBtnStatus == "1"
                ? "通过"
                : publishRightBtnStatus == "3"
                ? "发布"
                : "过期"
            }}</Button 
          >
          <Button
          type="warning"
             @click="passAndSend"
            style="margin-left: 10px"
            :style="{
              display:
                buttonRoot == '1002' ? '' : 'none',
            }"
            >通过并加急发布</Button 
          >
        </template>
      </LiefengModal>
      <!-- 查看 -->
      <LiefengModal
        title="查看投票"
        :value="seeModalStatus"
        :fullscreen="true"
        @input="seeModalStatusFn"
      >
        <template v-slot:contentarea>
            <PublishVote :infoId="infoId" :menuCodeObj="menuCodeObj" v-if="seeModalStatus"></PublishVote>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/voteindex")
import PublishVote from "@/views/vote/childrens/publishVote";
import ChangeVote from "@/views/vote/childrens/changeVote";
import AddVote from "@/views/vote/childrens/addVote";
import LiefengModal from "@/components/LiefengModal";
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";

export default {
  data() {
    return {
      searchData: {},
      //查看弹窗
      seeModalStatus: false,
      //判断打开新增/修改/发布审核弹窗的状态
      addVoteStatus: false,
      changeVoteStatus: false,
      publishVoteStatus: false,
      loading: false,
      infoId: "", // 修改row id
      //publish按钮名称标识
      publishRightBtnStatus: "",
      //按钮权限
      buttonRoot: "",
      //表头列表
      talbeColumns: [
        {
          title: "信息名称",
          key: "title",
          width: 140,
          align: "center",
        },
        {
          title: "编码",
          key: "code",
          width: 90,
          align: "center",
        },
        {
          title: "发布范围",
          minWidth: 130,
          key: "privilegeString",
          align: "center",
        },
        {
          title: "公布时间",
          key: "startTime",
          width: 170,
          align: "center",
        },
        {
          title: "截至时间",
          key: "endTime",
          width: 170,
          align: "center",
        },
        {
          title: "拟制人",
          key: "creatorName",
          width: 90,
          align: "center",
        },
        {
          title: "推荐",
          key: "recommend",
          width: 165,
          align: "center",
          render: (h,params) => {
            return ('div',[
              h('RadioGroup',{
                attrs: {
                  value: params.row.recommend,
                },
                on: {
                  "on-change": (status) => {
                    this.infoId = params.row.id;
                    this.recommend = status;
                    this.orgCode = params.row.orgCode
                    this.sortChangeFn();
                  }
                }
              },[
                h('Radio',{
                  attrs: {
                    label: "1",
                    disabled: params.row.status == '已发布' || params.row.status == '已过期' ? false : true
                  }
                },"推荐"),
                 h('Radio',{
                  attrs: {
                    label: "0",
                    disabled: params.row.status == '已发布' || params.row.status == '已过期' ? false : true
                  }
                },"不推荐")
              ])
            ])
          }
        },
        {
          title: "排序",
          key: "sort",
          width: 110,
          align: "center",
          render: (h,params) => {
            return h('InputNumber',{
              attrs: {
                min: 1,
                max: 999999999,
                value: params.row.sort,
                precision:0
              },
              style: {
                width: '100%',
              },
              on: {
                "on-change": (val) => {    //修改index值，渲染文本
                  console.log(val,'val')
                if(val == params.row.sort) return;
                this.recommend = params.row.recommend;
                this.sortNum = val;
                this.infoId = params.row.id;
                this.orgCode = params.row.orgCode
                clearTimeout(this.timer);
                console.log('排序',this.orgCode,params.row.orgCode);
               this.timer = setTimeout(() => {
                 this.sortChangeFn();
               },1000)
               },
              }
            })
          }
        },
        {
          title: "审核状态",
          key: "status",
          width: 130,
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          fixed: 'right',
          align: "center",
         width: 180,
          render: (h, params) => {
            return h("div",{
                style: {
                  textAlign: "left"
                }
              }, [
              h(
                "Button",
                {
                   attrs: {
                    type:"info",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.changeVote(params.row);
                    },
                  },
                  style: {
                     width: '65px',
                    marginRight: "10px",
                    textAlign: "center",
                    display: this.buttonRoot == "1001" ? "" : "none",
                  },
                },
                "修改" //1001
              ),
              h(
                "Button",
                {
                   attrs: {
                    type:"info",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.orgCode = params.row.orgCode
                      this.publish(params.row);
                    },
                  },
                  style: {
                     width: '65px',
                     marginRight: "10px",
                    textAlign: "center",
                    display:
                      this.buttonRoot == "1002" && params.row.status == "待审核"
                        ? ""
                        : "none",
                  },
                },
                "审核" //1002
              ),
              h(
                "Button",
                {
                  attrs: {
                    type:"info",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.orgCode = params.row.orgCode
                      this.publish(params.row);
                    },
                  },
                  style: {
                     width: '65px',
                     marginRight: "10px",
                    textAlign: "center",
                    display:
                      this.buttonRoot == "1003" &&
                      (
                        params.row.status == "待发布")
                        ? ""
                        : "none",
                  },
                },
                "发布" //1003
              ),
              h(
                "Dropdown",
                {
                  props: {
                    transfer: true,
                    //trigger: "click",
                  },
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        type: "success",
                        size: "small",
                        icon: "ios-arrow-down"
                      },
                    },
                    "编辑"
                  ),
                  h(
                    "DropdownMenu",
                    {
                      slot: "list",
                    },
                    [
                       h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.seeVote(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "查看"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.orgCode = params.row.orgCode
                              this.publish(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                            display:
                              params.row.status == "已发布"
                                ? ""
                                : "none",
                          },
                        },
                        "过期"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.deleteVote(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "删除"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.voteRecord(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                            display:
                              params.row.status == "已发布" ||
                              params.row.status == "已过期"
                                ? ""
                                : "none",
                          },
                        },
                        "投票记录"
                      ),
                      h(
                          "DropdownItem",
                          {
                            nativeOn: {
                              click: () => {
                                this.$router.push(`/readunread?type=vote&msgId=${params.row.id}&menuId=${this.$route.query.menuId}`)
                              },
                            },
                            style: {
                              textAlign: "center",
                               display:
                              params.row.status == "已发布" ||
                              params.row.status == "已过期"
                                ? ""
                                : "none",
                            },
                          },
                          "已读未读"
                        ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.voteManage(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "选项管理"
                      ),
                    ]
                  ),
                ]
              ),
            ]);
          },
        },
      ],
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 20,
      menuId: "",
      type: "",
      columnCode: "",
      status: "",
       //type,columnCode等接口数据,传给子组件
      menuCodeObj: {},
      //信息状态
      status1: 0,
      status2: 0,
      status3: 0,
      status4: 0,
      status5: 0,
      addChangeModalStatus: false,
      addChangeTitle: "",
      disableBtn: "0",
      //推荐/排序
      recommend:'',
      infoId: '',
      sortNum: '',
       //活跃menu
      activeMenu: '',
      //排序定时器
      timer: null,

      // 存储当前信息的orgCode
      orgCode:''
    };
  },
  methods: {

    //查看弹窗
    seeModalStatusFn(status) {
      this.seeModalStatus = status;
    },
    seeVote(row) {
      this.infoId = row.id;
      this.seeModalStatus = true;
    },
    //排序
    sortChangeFn(obj) {
      let params = {
        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        infoId: this.infoId,  //当前信息类id
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: this.orgCode,
        sort: this.sortNum,
        status: this.status,
        recommend: this.recommend
      }

      this.$post("/old/api/pc/affiche/emp/changeAfficheField",params).then(res => {
        if(res.code == 200) {
          this.pageChange({
            page: this.currentPage,
            pageSize: this.pageSize
          });
          this.$Message.success({
            background: true,
            content: "修改成功"
          })
        }else {
          this.$Message.error({
            background: true,
            content: "修改失败"
          })
        }
      }).catch(err => {
        console.log(err);
      })
    },
     //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "信息名称",
            "编码",
            "发布范围",
            "公布时间",
            "截至时间",
            "拟制人",
            "推荐",
            "排序",
            "审核状态"
          ];
          let filterVal = [
            "title",
            "code",
            "privilegeString",
            "startTime",
            "endTime",
            "creatorName",
            "recommend",
            "sort",
            "status"
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "投票组件列表"
          );
        },
      });
    },
    //查看/修改/发布组件菜单栏
    comSelectMenu(value) {
      this.disableBtn = value;
    },
    //调用子组件的相应方法
    //上一步
    backStep() {
      if (this.addVoteStatus) {
        this.$refs.addVote.nextStep(-1);
      } else if (this.changeVoteStatus) {
        this.$refs.changeVote.nextStep(-1);
      } else {
        this.$refs.publishVote.nextStep(-1);
      }
    },

    //下一步
    nextStep() {
      if (this.addVoteStatus) {
        this.$refs.addVote.nextStep(1);
      } else if (this.changeVoteStatus) {
        this.$refs.changeVote.nextStep(1);
      } else {
        this.$refs.publishVote.nextStep(1);
      }
    },
    //驳回
    rejectVote() {
      this.$refs.publishVote.reject();
    },
     // 通过并发布按钮
    passAndSend(){
      this.$refs.publishVote.passAndSend();
    },
    //通过/发布/过期
    saveVote() {
      if (this.addVoteStatus) {   //新增模块
        this.$refs.addVote.save();
      } else if (this.changeVoteStatus) {   //修改模块
        this.$refs.changeVote.save();
      } else {  //查看模块
        this.$refs.publishVote.publish();
      }
    },
    contentFn() { //预览文本
      console.log('执行了1')
      if (this.addVoteStatus) {   //新增模块
        this.$refs.addVote.contentFn();
      } else if (this.changeVoteStatus) {   //修改模块
        this.$refs.changeVote.contentFn()
      } else {  //查看模块
        this.$refs.publishVote.contentFn();
      }
    },

    //新增/修改弹窗状态改变
    addChangeModalFn(status) {
      if (!status) {
        this.addVoteStatus = false;
        this.changeVoteStatus = false;
        this.publishVoteStatus = false;
      }
      this.disableBtn = "0";
      this.addChangeModalStatus = status;
    },
    //左侧信息菜单
    selectMenu(name) {
      if (name == "7") {
        //6代表全部信息
        this.status = "";
      } else {
        this.status = name;
      }
      this.currentPage = 1
      this.pageChange({
        page: this.currentPage,
        pageSize: this.pageSize,
      });
    },
    //新增
    addVote() {
      this.addVoteStatus = true;
      this.changeVoteStatus = false;
      this.addChangeTitle = "新增投票";
      this.addChangeModalStatus = true;
    },
    //修改
    changeVote(value) {
      this.infoId = value.id;
      this.addVoteStatus = false;
      this.changeVoteStatus = true;
      this.addChangeTitle = "修改投票";
      this.addChangeModalStatus = true;
    },
    //审核/发布/过期
    publish(value) {
      this.infoId = value.id;
      this.addVoteStatus = false;
      this.changeVoteStatus = false;
      this.addChangeModalStatus = true;
      this.publishVoteStatus = true;
    },
    //动态修改审核/发布/过期title
    modalStatusFn(status) {
      this.publishRightBtnStatus = status;
      if (status == "1") {
        this.addChangeTitle = "审核投票";
      } else if (status == "3") {
        this.addChangeTitle = "发布投票";
      } else {
        this.addChangeTitle = "过期投票";
      }
    },
    //删除
    deleteVote(row) {
      this.$Modal.confirm({
        title: "温馨提示",
        content: "<p>确定删除该投票信息？</p>",
        onOk: () => {
          this.$get("/voteapi/api/pc/affiche/deleteAfficheInfo", {
            infoId: row.id,
          }).then((res) => {
            if (res.code == 200) {
              this.pageChange({
                page: this.currentPage,
                pageSize: this.pageSize,
              });
              this.$Message.success({
                background: true,
                content: "删除成功"
              })
            }else {
              this.$Message.error({
                background: true,
                content: "删除失败"
              })
            }
          }).catch(err => {
            console.log(err);
          })
        },
      });
    },
    //投票记录
    voteRecord(row) {
      this.$router.push({
        path: "/voterecord",
        query: {
          id: row.id,
          menuId: this.menuId
        },
      });
    },
    //选项管理
    voteManage(row) {
      this.$router.push({
        path: "/votemanage",
        query: {
          id: row.id,
          menuId: this.menuId
        },
      });
    },
    //修改res数据的时间格式
    timeFilter(res, time) {
      res.dataList.map((item, index, arr) => {
        let startTime = new Date(item[time]);
        let startYear = startTime.getFullYear();
        let startMonth =
          startTime.getMonth() + 1 < 10
            ? "0" + (startTime.getMonth() + 1)
            : startTime.getMonth() + 1;
        let startDay =
          startTime.getDate() < 10
            ? "0" + startTime.getDate()
            : startTime.getDate();
        let startHorus =
          startTime.getHours() < 10
            ? "0" + startTime.getHours()
            : startTime.getHours();
        let startMinutes =
          startTime.getMinutes() < 10
            ? "0" + startTime.getMinutes()
            : startTime.getMinutes();
        let startSeconds =
          startTime.getSeconds() < 10
            ? "0" + startTime.getSeconds()
            : startTime.getSeconds();
        arr[index][time] =
          startYear +
          "-" +
          startMonth +
          "-" +
          startDay +
          " " +
          startHorus +
          ":" +
          startMinutes +
          ":" +
          startSeconds;
      });

      this.tableData = res.dataList;
    },
    search() {
      this.pageChange({
        page: 1,
        pageSize: this.pageSize
      })
    },
    rest() {
      this.searchData = {};
      this.pageChange({
        page: this.currentPage,
        pageSize: this.pageSize
      })
    },
    pageChange(obj) {
      this.loading = true;
      this.$get("/voteapi/api/pc/affiche/getDataList", {
        type: this.type, //从url上面取
        status: this.status,
        title: this.searchData.title,
        code: this.searchData.code,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        subtype: "",
        staffId: parent.vue.loginInfo.userinfo.id,
        operType: "view", //先写死
        columnCode: this.columnCode, //目前对应菜单menuID，从url拿
        page: obj.page,
        pageSize: obj.pageSize,
      }).then((res) => {
        if(res.code == 200) {
          // 获取信息状态的数量
          this.messageStatusCount();
          this.total = res.maxCount;
        this.currentPage = res.currentPage;
        this.pageSize = res.pageSize;

        //修改res数据格式
        res.dataList.map((item, index, arr) => {
          // arr[index].recommend = arr[index].recommend == 1 ? "推荐" : "不推荐";

          switch (item.status) {
            case "1":
              arr[index].status = "待审核";
              break;
            case "2":
              arr[index].status = "待提交/已驳回";
              break;
            case "3":
              arr[index].status = "待发布";
              break;
            case "4":
              arr[index].status = "已发布";
              break;
            case "5":
              arr[index].status = "已过期";
              break;
            default:
              arr[index].status = "已归档";
          }
        });
        this.timeFilter(res, "startTime");
        this.timeFilter(res, "endTime");
        this.loading = false;
        }else {
           this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败"
          })
        }
      });
    },
    messageStatusCount() {
      //获取信息状态的数量
      this.$get("/voteapi/api/pc/affiche/emp/groupStatus", {
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        oemCode: parent.vue.oemInfo.oemCode,
        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
         businessType: this.menuCodeObj.businessType,
        functionType: this.menuCodeObj.functionType,
        type: this.type,
      }).then((res) => {
        if (res.code == 200) {
          this.status1 = res.data.auditNumber;
          this.status2 = res.data.rejectNumber;
          this.status3 = res.data.waitNumber;
          // this.status4 = res.data.rejectNumber;  //草稿箱数量
        }
      });
    },
  },
  async created() {
    let userButtonRoot = parent.vue.userButtonRoot;
    this.buttonRoot = userButtonRoot.includes("1001")
      ? "1001"
      : userButtonRoot.includes("1002")
      ? "1002"
      : userButtonRoot.includes("1003")
      ? "1003"
      : "";
    // this.buttonRoot = '1001'
    this.status =
      this.buttonRoot == "1001"
        ? "2"
        : this.buttonRoot == "1002"
        ? "1"
        : this.buttonRoot == "1003"
        ? "3"
        : "";
    //选中第一项菜单
    this.activeMenu = this.status;
    this.menuId = this.$core.getUrlParam("menuId") || "";

     await this.$get('/old/api/pc/menu/selectColumnByMenuCode',{
      menuCode: this.menuId
    }).then(res => {
      if(res.code == 200&&res.data) {
        this.type = res.data.columnType;
        this.columnCode = res.data.columnCode;
         this.menuCodeObj = res.data;
      }else {
        this.$Message.error({
          background: true,
          content: res.desc
        })
      }
    });
    this.pageChange({
      page: 1,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengTable,
    LiefengContent,
    LiefengModal,
    AddVote,
    ChangeVote,
    PublishVote,
  },
};
</script>

<style scoped lang='less'>
.table-left {
  float: left;
  width: 160px;
  /deep/.ivu-menu {
    width: 160px !important;
  }
}
/deep/.ivu-tooltip-inner{
  width:200px;
  white-space: normal;
} 
/deep/.ivu-menu-item{
    z-index: 3;
}
</style>
